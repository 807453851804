import type { ReactNode } from 'react';
import { RadioGroup, Radio } from '@headlessui/react';
import { SubscriptionPlanInterval, SubscriptionPlanStripe } from 'generated/graphql';
import SubscriptionPlan from 'src/components/subscription-plan';
import type { CurrencyCode } from 'src/utilities/currency-code-helpers';

type SubscriptionPlansRadioButtonsProps = {
  subscriptionPlans?: SubscriptionPlanStripe[];
  subscriptionPlanInterval: SubscriptionPlanInterval;
  currencyCode?: CurrencyCode;
  isPlanEnabled: (plan: SubscriptionPlanStripe) => boolean;
  onChange: (value: SubscriptionPlanInterval) => void;
  children: ReactNode;
};

/**
 * A selectable list of subscription plans
 */
export default function SubscriptionPlansSelector({
  subscriptionPlans,
  subscriptionPlanInterval,
  currencyCode,
  isPlanEnabled,
  onChange,
  children,
}: SubscriptionPlansRadioButtonsProps) {
  return (
    <div className="flex flex-col gap-6 pt-8">
      <RadioGroup
        value={subscriptionPlanInterval}
        onChange={onChange}
        className="flex flex-col gap-3 lg:flex-row lg:flex-nowrap"
      >
        {subscriptionPlans?.map((subscriptionPlan) => (
          <Radio
            value={subscriptionPlan.interval}
            key={subscriptionPlan.interval}
            className="focus-visible:outline-none lg:flex-1"
            data-test={`subscription-plan-option:${subscriptionPlan.interval}`.toLowerCase()}
            disabled={isPlanEnabled(subscriptionPlan) === false}
          >
            {({ checked, disabled, focus }) => (
              <SubscriptionPlan
                subscriptionPlan={subscriptionPlan}
                allSubscriptionPlans={subscriptionPlans}
                currencyCode={currencyCode}
                focus={focus}
                checked={checked}
                disabled={disabled}
              />
            )}
          </Radio>
        ))}
      </RadioGroup>
      {children}
    </div>
  );
}
